.image-block {
    height: 15px;
    display: inline-block;
  }

  .column {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .columnMeds {
    float: left;
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  .column10 {
    margin-left: auto;
    margin-right: 0;
    min-width: 55px;
    width: 10%;
    padding-top: 10px;
    padding-right: 10px;
  }
  
  .column20 {
    float: left;
    width: 20%;
    padding: 10px;
  }

  .column30 {
    float: left;
    width: 30%;
    padding: 10px;
  }

  .column40 {
    float: left;
    width: 40%;
    padding: 10px;
  }

  .column50 {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .column60 {
    float: left;
    width: 67%;
    padding: 10px;
  }

.toolsColumn {
  height: 960px;
}

  h2{
    white-space: pre-wrap;
    margin-top: 0;
    font-style: normal;
    line-height: 1.2em;
    font-size: 1.6em;
  }

  h3 {
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .06em;
}

  .title {
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .06em;
  }

  a{
    text-decoration-line: underline;
    text-decoration-color: rgba(0,0,0,.2);
    color:#000;
    display: inline;
    }


  p {
    font-family:Helvetica,arial,sans-serif;
    font-weight: normal;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.8em;
    text-transform: none;
    text-decoration: none;
    margin: 0 0 1em;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.profileDesc {
  font-family:Helvetica,arial,sans-serif;
  font-size: 15px;
  font-style: normal;
  line-height: 1.8em;
  text-transform: none;
  text-decoration: none;
  margin: 0 0 1em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.social-icon img {
  width: 50px;
  padding: 8px;
}

