.App {
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: block;
  color: white;
}

.Diagnosis-header {
  display: block;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.h5, h5 {
  font-size: 1.1rem;
}

@media print {
  .nav {
    display: none;
  }

  .btn {
    display: none;
  }
}

@media screen {
  * {
    box-sizing: border-box;
  }
  body {
    padding: 0 20px;
  }

  a {
    white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-word; /* Internet Explorer 5.5+ */
  }

  .full-width {
    height: auto;
    position: relative;
    overflow: auto;
  }

  .segmented-options {
    display: flex;
    position: relative;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 1;
    font-size: 12px;
    border: 0px solid #000;
    border-radius: 8px;
    color: #000;
    margin-top: 8px;
  }
  .segmented-options-vertical {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
  }
  .segmented-options > input {
    display: none;
  }
  .segmented-options > input:checked + label {
    color: #fff;
  }
  .segmented-options
    > input:nth-of-type(1):checked
    ~ label:last-of-type:before {
    transform: translateX(calc(-100% + 0px));
    display: none;
  }
  .segmented-options
    > input:nth-of-type(2):checked
    ~ label:last-of-type:before {
    transform: translateX(calc(0% + 0px));
    display: inline-block;
  }
  .segmented-options
    > input:nth-of-type(3):checked
    ~ label:last-of-type:before {
    transform: translateX(calc(100% + 0px));
    display: inline-block;
  }
  .segmented-options
    > input:nth-of-type(4):checked
    ~ label:last-of-type:before {
    transform: translateX(calc(200% + 0px));
    display: inline-block;
  }
  .segmented-options
    > input:nth-of-type(5):checked
    ~ label:last-of-type:before {
    transform: translateX(calc(300% + 0px));
    display: inline-block;
  }
  .segmented-options
    > input:nth-of-type(6):checked
    ~ label:last-of-type:before {
    transform: translateX(calc(400% + 0px));
    display: inline-block;
  }
  .segmented-options-vertical
    > input:nth-of-type(1):checked
    ~ label:last-of-type:before {
    transform: translateY(calc(-100% + 0px));
    display: none;
  }
  .segmented-options-vertical
    > input:nth-of-type(2):checked
    ~ label:last-of-type:before {
    transform: translateY(calc(0% + 0px));
    display: inline-block;
  }
  .segmented-options-vertical
    > input:nth-of-type(3):checked
    ~ label:last-of-type:before {
    transform: translateY(calc(100% + 0px));
    display: inline-block;
  }
  .segmented-options-vertical
    > input:nth-of-type(4):checked
    ~ label:last-of-type:before {
    transform: translateY(calc(200% + 0px));
    display: inline-block;
  }
  .segmented-options-vertical
    > input:nth-of-type(5):checked
    ~ label:last-of-type:before {
    transform: translateY(calc(300% + 0px));
    display: inline-block;
  }
  .segmented-options-vertical
    > input:nth-of-type(6):checked
    ~ label:last-of-type:before {
    transform: translateY(calc(400% + 0px));
    display: inline-block;
  }
  .segmented-options label {
    flex: 1;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .segmented-options-vertical label {
    text-align: left;
    overflow: hidden;
    cursor: pointer;
    display: block;
    width: 100%;
  }
  .segmented-options label:last-of-type:before {
    content: "";
    display: block;
    margin: 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translateX(0);
  }
  .segmented-options-vertical label:last-of-type:before {
    content: "";
    display: block;
    margin: 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translateY(0);
  }

  .segmented-options-2 label:last-of-type:before {
    max-width: calc(50% - 0px);
  }
  .segmented-options-3 label:last-of-type:before {
    max-width: calc(33.333333333% - 0px);
  }
  .segmented-options-4 label:last-of-type:before {
    max-width: calc(25% - 0px);
  }
  .segmented-options-5 label:last-of-type:before {
    max-width: calc(20% - 0px);
  }

  .segmented-options-vertical-2 label:last-of-type:before {
    max-height: calc(50% - 0px);
  }
  .segmented-options-vertical-3 label:last-of-type:before {
    max-height: calc(33.333333333% - 0px);
  }
  .segmented-options-vertical-4 label:last-of-type:before {
    max-height: calc(25% - 0px);
  }
  .segmented-options-vertical-5 label:last-of-type:before {
    max-height: calc(20% - 0px);
  }

  .segmented-options label {
    padding: 6px 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 3px;
    margin-right: 0px;
    transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  }
  .segmented-options-vertical label {
    padding: 6px 6px;
    margin-top: 0px;
    margin-bottom: 3px;
    margin-left: 0px;
    margin-right: 0px;
    transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  }
  .segmented-options label:before {
    transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
  }
  .segmented-options label.selected {
    background-color: #F3AF22;
  }
  .form-check-input:checked~.form-check-label::before {
    border-color: #F3AF22;
    background-color: #F3AF22;
  }

  .form-check-input:checked {
    border-color: #F3AF22;
    background-color: #F3AF22;
  }
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='red'/%3e%3c/svg%3e");
  }

  .form-check-input:focus {
    border-color: #F3AF22;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(243, 173, 34, 0.25);
  }

  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #F3AF22;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(243, 173, 34, 0.25);
  }

  .form-check-input[type=checkbox]:indeterminate {
    background-color: #F3AF22;
    border-color: #F3AF22;
  }
  
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='gray'/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }

  
  button {
    font-size: 18px;
  }

  body,
  html {
    width: 100%;
  }

  body {
    display: block;
    font-size: 15px;
  }

  .btnTitle {
    border: none;
    background-color: transparent;
  }

  .question {
    /* todo */
  }

  .question-section {
    padding-top: 2px;
  }

  .question-content {
    padding: 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow:0px 0px 4px #ccc;
    margin-bottom: 18px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 48%));
    gap: 0px 10px;
    padding: 10px;
  }

  .radioDiv {
    margin-top: 0px;
    height: auto;
  }

  .radio {
    border: 0px;
    width: 15px;
    height: auto;
  }
  input[type="radio"]{
      vertical-align: baseline;
  }
  .radioLabel {
    font-size: 15px;
    margin-left: 10px;
    margin-bottom: 0px;
  }

  #breadcrumb {
    font-size: 13px;
  }

  .back {
  }

  .next {
    margin-top: 12px;
  }

  .figure-p {
    position: relative;
    max-width: 386px;
    margin: 20px;
  }

  .figure-l {
    position: relative;
    margin: 20px;
  }

  .figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    border: 2px solid black;
    border-radius: 8px;
  }

  .figureol {
    position: absolute;
    z-index: 3;
    position: absolute;
  }

  .btn-primary {
    border-radius: 8px;
    border-color: white;
    color: #fff;
    --bs-btn-color: #fff;
    --bs-btn-bg: #262626;
    --bs-btn-border-color: #262626;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #272727;
    --bs-btn-hover-border-color: #272727;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #272727;
    --bs-btn-active-border-color: #272727;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #777676;
    --bs-btn-disabled-border-color: #777676;
}

  .btn-primary:hover {
    color: #fff;
  }

  .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    box-shadow: none;
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-primary.management {
    background-color: #7a006e;
    float: right;
  }

  .btn-secondary {
    background-color: #666;
    border-radius: 8px;
  }

  .custom-btn-group {
    float: right;
  }

  .custom-btn-group .btn-primary {
    float: none;
    margin-bottom: 12px;
  }

  .tab-content {
    padding-bottom: 2.5rem;
  }

  .tab-pane {
    padding: 10px;
    margin: 20px 10px;
  }

  .card-holder {
    margin-top: 20px;
  }

  .card-holder-text {
    margin-left: 21.5px;
    margin-right: auto;
    width: 70%;
    min-width: 705px;
  }

  .pdfHolder {
    overflow: scroll;
    height: 79vh;
    margin-top: 20px;
    width: 612px;
    margin-left: auto;
    margin-right: auto;
  }

  .med-card {
    margin: 12px auto;
    border: 0px;
    border-radius: 8px;
    box-shadow:0px 0px 8px #575757;
  }

  .med-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .medExpanded {
    grid-row-end: span 99;
  }

  .card {
    margin: 20px auto;
    border: 0px;
    border-radius: 8px;
    box-shadow:0px 0px 8px #575757;
    white-space: pre-wrap;
  }

  .card-rec {
    width: 100% !important;
    margin: 0px 0px 20px 0px !important;
    border: 0px;
    border-radius: 8px;
    box-shadow:0px 0px 8px #575757;
  }

  .card-img {
    margin-left: auto;
    display: block;
    margin-right: auto;
  }

  .card-header {
    display: inline-block;
  }

  .card-title {
    display: inline-block;
    padding-left: 0px;
    vertical-align: middle;
    margin-bottom: 0;
    width: calc(100% - 8px);
  }

  .button-img.tool {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
      brightness(103%) contrast(103%);
  }

  .brain {
    max-width: 42px;
    max-height: 42px;
    border-width: 0px;
    display: inline-block;
    margin-top: -8px;
  }

  .bp {
    display: flex;
    flex-direction: row;  
  }

  .bullet {
    width: 8px;
    border-radius: 5px;
    padding-right: 8px;   
  }

  .form {
    margin: 20px auto 0px;
    /* min-width: 705px; */
  }

  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 8px;
  }
  .custom-btn-group .btn:last-child {
    border-radius: 8px;
  }

  .button-img {
    display: inline-block;
    height: 21px;
    vertical-align: text-bottom;
    margin-right: 5px;
  }

  .button-title {
    display: inline-block;
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    cursor: pointer;
    text-decoration: underline;
  }

  .center {
    text-align: center;
  }

  .centered  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .phone-nav-view {
    position: relative;
  }

  /* .phone-nav-button-holder {
    display: flex;
    .management {
      color: #7a006e;
    }
    .tools {
      color: #008b7e;
    }
    .publications {
      color: #260946;
    }
    .resources {
      color: #fd5f00;
    }
  } */

  .phone-nav-button {
    z-index: 11;
  }

  .phone-nav-label {
    margin-top: -5px;
    margin-left: 10px;
  }

}

@media (min-width: 576px) {
  .modal-dialog {
      max-width: 80%;
      margin: 1.75rem auto;
  }

  .card {
    width: 70%;
  }
}

.App {
  height: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.full-view {
  position: relative;
  min-height: 100vh;
}

.about-us-logo {
  padding-top: 20px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.down_arrow {
  align-self: center;
  height: 42px;
}

.arrow_box {
  margin: 2px auto;
}

.transparent {
  background-color: rgba(255, 255, 255, 0.0)
}

.card.management {
  margin: 10px auto;
}

.dark {
  text-align: center;
}

.pink {
  background-color: #EDE2F0;
}

.gray {
  background-color: #E5E5E5;
}

.yellow {
  background-color: #EDC649;
}

.btn-yellow {
  background-color: #F6D25C !important; 
  border-color: #F6D25C !important;
  color: #212121 !important;
  padding: 10px 27px !important;
  text-decoration: none;
  border-radius:8px;
  font-family: "Lato",sans-serif;
  font-size: 9pt;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn {
  border-radius:10px !important;
  text-transform: uppercase;
  font-weight: 800;
}

.btn-sm {
  background-color: #272727; 
  border-color: #272727;
  padding: 5px 10px;
  font-family: "Lato",sans-serif;
  font-size: 10px;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}

.btn:hover {
  opacity: 0.8;
}

.btn-sm:hover {
  background-color: #262626;
  border-color: #262626;
  color: #fff;
  opacity: 1;
}

.btn-primary:hover, .btn-primary:active, .btn-sm:active {
  background-color: #272727; 
  border-color: #272727;
  opacity: 0.8;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #272727; 
  border-color: #272727;
  opacity: 0.8;
}

.pricing-style-one {
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.pricing-icon img {
  max-height: 400px;
  border-radius: 0 !important;
}

.pricing-style-one .pricing-icon img {
  width: auto;
  margin: auto;
}

.pricing-header p {
  margin-top: 24px;
  width: 100%;
  max-width: 400px;
}

ul.data {
  list-style-type: disc !important;
  padding-left:1em !important;
  margin-left:1em;
}

ul .guide {
  list-style-type: disc !important;
  margin-left:1em;
  font-size: 14px;
  padding-bottom: 5px;
} 

li {
  font-size: 12px;
}

.methodList {
  font-size: 16px;
  padding-top: 15px;
}

.medList {
  font-size: 15px;
  padding: 5px;
}

h3 {
  padding-top: 20px;
  text-transform: uppercase;
}

.sticky .logo {
  display: none
}

.dropdown-menu.columns-2 {
	min-width: 400px;
}

.dropdown-menu.columns-3 {
	min-width: 600px;
  margin-left:-100%;
}

.hideObj {
  display: none;
}

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md .form-check-input {
  height: 1.8rem;
  width: calc(2.3rem + 0.9rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
  margin-right: 4px;
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.form-control {
  height: auto;
}

select.form-control {
  appearance: menulist;
}

.disabled {
  background-color: #ddd;
  color: #888;
}

.grid-2 .line {
  grid-column: 1 / -1;
  height: 1px;
  background: rgb(183, 183, 183);
}

.grid-2 {
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 30px;
  padding-top: 15px;
  padding-bottom: 55px;
}

.grid-home {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns:30% 65%;
  grid-gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.medrow {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen
  and (max-width: 770px) {
    .grid-2 {
      grid-template-columns: repeat(1, 100%);
      padding-top: 20px;
    }
    .grid-2 .line-single {
      grid-column: 1 / -1;
      height: 1px;
      background: rgb(183, 183, 183);
    }

    .App-header {
      height: 20px;
    }

    .carousel h2{
      margin-top: -40px;
      font-size: 10pt;
    }

    .carousel p{
      font-size: 6pt;
    }

    .gridItem:nth-of-type(1) {
      order:1
    }

    .gridItem:nth-of-type(2) {
      order:0
    }

    .medrow {
      padding-left: 15px;
      padding-right: 15px;
    }
}

.grid3 {
  display: grid;
}

.grid2 {
  display: grid;
}

.grid-30_70 {
  display: grid;
}

.mobile {
  max-width: 700px;
}
  
.welcomeDiv {
  position:relative;
  border-radius: 8px;
  float:left;
  width:100%;
  padding-bottom:10px;
  position: relative;
  transform: translateZ(10);
}

.wacofooter{
  background: url("../../assets/images/wacoFooter.png") no-repeat;
  background-size: cover;
  height: 275px;
}

.talksDivFull {
  background: linear-gradient(to bottom, rgba(255,255,255,0) 20%,
              rgba(255,255,255,1)), url("../../assets/images/background_header.png") no-repeat;
  background-size: cover;
  border-radius: 8px;
  padding:10px;
}

.talksDiv {
  position:relative;
  border-radius: 8px;
  float:left;
  width:100%;
  padding-bottom:10px;
  position: relative;
  transform: translateZ(10);
}

.floatingPDF {
  left:50%;
  margin-bottom:100px;
  padding-left:10px; 
  padding-right:10px;
  height:auto;
  position:absolute;
  top:0px;
  z-index:100;
  transform: translate(-50%, 0);
}

.grid-container-tags {
  display: grid;
  grid-template-columns: repeat(3, auto);
}



.grid-container-tags>* {
  margin: 5px;
  padding: 5px;
  border: 1px solid black;
}

.card-container {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;
  transform: translate3d(0, 0, 200px);
}

.card-container.flipped .front {
  transform: rotateY(180deg);
}
.card-container.flipped .back {
  transform: rotateY(0deg);
  transform: translate3d(0, 0, 200px);
}
.card-container .front, .card-container .back {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: block;
  background: #ffcf00;
  box-shadow: 2px 2px 5px #b7b99d;
  padding: 5px;
  position: absolute;
  border-radius: 10px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: -webkit-transform ease 500ms;
  transition: transform ease 500ms;
}

.card-container .back .centered{
  text-align: center;
  width: 100%;
  font-size: 20px;
  padding: 0em 1em 0em 1em;
  color: #272727;
 /* front tile styles go here! */
}

.card-container .front {
  z-index: 2;
  transform: rotateY(0deg);
}

.card-container .front .centered{
  text-align: center;
  width: 100%;
  color: #272727;
}

@media screen
   and (max-width: 689px) {

    .comp-grid {
      grid-template-columns:repeat(auto-fit, minmax(min-content, 100%)) !important;
    }
  }

@media screen
   and (max-width: 615px) {
    
    .homeTitleFont {
      font-size: 28pt;
    }

    .welcomeText{
      font-size: 6vw;
    }

    .card-container {
      margin: 0px;
    }

    .card-container .front .centered{
      font-size: 15px;
    }

    .tool-step-img {
      margin-top:50px;
    }

    .grid3 {
      float: center !important;
      width:  100%;
      grid-template-columns: repeat(1, 100%);
      grid-gap: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .grid2 {
      float: center !important;
      width:  100%;
      grid-template-columns: repeat(1, 100%);
      grid-gap: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .grid-30_70 {
      float: center !important;
      width:  100%;
      grid-template-columns: 30% 70%;
      grid-gap: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    h3 {
      line-height:1.3em;
      font-size:  0.81em;
    }

    .subtitle {
      font-size: 11pt;
    }

    .about_faq_header {
      font-size: 13pt;
    }
    
    .faq_title {
      font-size: 13pt;
    }
  }

@media screen
  and (max-width: 1245px) and (min-width: 750px) {

    .comp-grid {
      grid-template-columns:repeat(auto-fit, minmax(min-content, 48%)) !important;
    }
    .homeTitleFont {
      font-size: 32pt;
    }

    .carousel h2{
      font-size: 18pt;
    }

    .carousel p{
      font-size: 14pt;
    }

}

@media screen
  and (max-width: 1401px) {
    .socialIcons {
      display: none;
    }
  }


@media screen
  and (max-width: 991px) {
    .titleFont {
      font-size: 24pt;
    }

    .Diagnosis-header {
      margin: 0px 0px 50px 0px;
      max-height: 200px;
    }
    .desktop {
      display: none;
    }

    .card-container {
      height: 100px;
    }

    .ignoreClick {
      padding-left: 10px;
      padding-right: 10px;
    }
      
    .btn-primary {
      background-color: #272727; 
      border-color: #272727;
      font-family: "Lato",sans-serif;
      font-size: 10px;
      font-weight: 800;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      background-color: #262626;
      border-color: #262626;
      min-height:35px;
    }

    #collapseDiv {
      height: 50px;
    }
     
    #collapseDiv.collapseDivClose{
      height: 0px;
    }
      
    #collapseDiv.collapseDivOpen{
      height: 50px;
    }

    .tool-step-img {
      width: 80%;
      max-width:430px;
      grid-gap:55px;
      
      margin-left:50%;
      transform:translateX(-50%);
      padding-left: 20px;
      padding-right: 20px;
    }

    .tool-step-text {
      margin-left:50% !important;
      transform:translateX(-50%);
      grid-gap:80px;
      grid-template-columns:repeat(3, 15%);
    }
    
    .labelFont {
      font-size: 8pt;
    }

}

@media only screen
  and (min-width: 1279px) {

    .comp-grid {
      grid-template-columns:repeat(auto-fit, minmax(min-content, 48%)) !important;
    }

    .homeTitleFont {
      font-size: 35pt;
    }

  }

@media only screen
  and (max-width: 1400px) {

    .homeTitleFont {
      font-size: 3.0vw;
    }

  }

@media only screen
  and (min-width: 992px) {

    .header-area {
      height: 165px;
    }

    .titleFont {
      font-size: 34pt;
    }

    .carousel h2{
      font-size: 20pt;
    }

    .carousel p{
      font-size: 14pt;
    }
    .welcomeText{
      padding-top:40px; 
      padding-left:40px; 
      width: 100%;
      height:auto;
    }

    h3 {
      line-height: 1.2em;
      font-size: 1.3em;
    }

    .tool-step-img {
      margin-top:5px;
    }
    
    .labelFont {
      font-size: 14pt;
    }

    .Diagnosis-header {
      margin: 70px 0px 50px 0px;
      max-height: 150px;
    }
    
    .card-container {
      margin: 10px;
    }

    .card-container .front .centered{
      font-size: 26px;
    }

    .grid3 {
      width:  100%;
      position:absolute;
      bottom: -50px;
      grid-template-columns: repeat(3, 32%);
      grid-gap: 10px;
      padding-top: 50px;
      padding-left: 10px;
    }

    .grid2 {
      width:  100%;
      position:absolute;
      top: -100px;
      grid-template-columns: repeat(2, 49%);
      grid-gap: 10px;
      padding-left: 10px;
    }

    .grid-30_70 {
      width:  100%;
      position:absolute;
      top: -100px;
      grid-template-columns: 30% 70%;
      grid-gap: 10px;
      padding-left: 10px;
    }
      
    .mobile {
      display: none;
    }
    
    .carousel-home {
      width: 100%;
      padding: 5px;
    }

    .ignoreClick {
      pointer-events: none;
    }

    a {
      pointer-events: auto !important;
    }
  
    .row {
      padding-left: 30px;
      padding-right: 1.25rem;
      margin-left: 0px;
      margin-right: 0px;
    }
    
    .card-container {
      height: 150px;
    }

    .welcomeDiv {
      height: 525px;
      background: url("../../assets/images/homeBackground.png") no-repeat;
      background-size:cover;
    }

    .growingDiv {
      margin-top: 40px;
      height: 545px;
      background: url("../../assets/images/growing-need.jpg") no-repeat;
      background-position: center;
    }

    .talksDiv {
      height: 240px;
    }

    .compLink:link {
      text-decoration: none; 
    }

    .compToolArea {
      padding-left: 50px;
    }
      
    .card-compMed {
      margin: 20px auto;
      border: 0px;
      border-radius: 8px;
      box-shadow:0px 0px 8px #575757;
    }

    .btn-primary {
      background-color: #272727; 
      border-color: #272727;
      padding: 21px 34px;
      font-family: "Lato",sans-serif;
      font-size: 15px;
      font-weight: 800;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      background-color: #262626;
      border-color: #262626;
      min-height:65px;
    }

    #collapseDiv {
      height: 185px;
    }
     
    #collapseDiv.collapseDivClose{
      height: 65px;
    }
      
    #collapseDiv.collapseDivOpen{
      height: 185px;
    }

    .tool-step-img {
      max-width:390px;
      grid-gap:60px;
      margin-left:50%;
      transform:translateX(-50%);
      padding-left: 10px;
      padding-right: 25px;
    }

    .tool-step-text {
      grid-gap:80px;
      grid-template-columns:repeat(3, 15%);
      margin-left:50% !important;
      transform:translateX(-50%);
    }
    
    .subtitle {
      font-size: 12pt;
    }

    .about_faq_header {
      font-size: 16pt;
    }

    .faq_title {
      font-size: 32;
    }
    
    .isPhone {
      display: none;
    }
}

.card-container .back {
  background: #ffcf00;
  transform: rotateY(-180deg);
}

.card-container .flipImg-Front{
  opacity:0.5;
  margin-left:50%;
  transform:translateX(-50%);
  width:auto;
  height:100%;
}

.card-container .flipImg-Back {
  opacity:0.5;
  margin-left:50%;
  transform:translateX(-50%) scaleX(-1);
  width:auto;
  height:100%;
}

.card-container .image-container .card-image {
  width: 100%;
  border-radius: 10px;
}

.card-container .image-container .title {
  font-size: 34px;
  position: absolute;
  margin-bottom: 10px;
  margin-left: 10px;
  bottom: 0;
  left: 0;
  color: #ffcf00;
}
.card-container .main-area {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.card-container .main-area .blog-post {
  height: 100%;
}
.card-container .main-area .blog-post .date {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
}
.card-container .main-area .blog-post .read-more {
  font-size: 12px;
  position: relative;
  bottom: 0px;
  text-align: right;
}
.card-container .main-area .blog-post .blog-content {
  height: 90%;
  font-size: 12px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel-control {
  width: 30%;/*put your desired width*/
  margin-top: 150px;/*put your desired top margin*/
}

.carousel-control-next {
  width: 8%;
  justify-content: right;
  padding-right: 10px;
  z-index: 15;
}

.carousel-control-prev {
  width: 8%;
  justify-content: left;
  padding-left: 10px;
  z-index: 15;
}

.carousel-right{
  width: 45%;
  right:10%; 
  top: 50%;
  transform:translateY(-50%);
  bottom:auto;
  left:auto;
}

.carousel-left{
  width: 45%;
  left:10%; 
  top: 50%;
  transform:translateY(-50%);
  bottom:auto;
  right:auto;
}

.video-container {
  margin-top:10px;
  margin-bottom:10px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.27198%;
  }

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }

.newsImg {
  border: 1px solid rgb(153, 153, 153);
  border-radius: 8px;
}

.contact {
  margin: 5px;
}

.contact-submit {
  width:20%;
  min-width: 100px;
}

.newsLink:link {
  text-decoration: none;
}
.newsLink:hover {
    color: #0f5ac9;
}

.canvas {
  position: absolute;
  display: block;
  left:0; right:0; top:0; bottom: 0;
  width: inherit;
  height: inherit;
  background-color: #fff1cf;
  z-index: -1;
  transform: translateZ(-1);
  border-radius: 8px;
}
 
#slideDiv {
 margin-left: 0px;
 transition: 1.5s;
 opacity: 0;
 overflow: hidden;
}

#slideDiv.slideClassIn{
  transition: 1.5s;
  opacity: 1;
 }

 #slideDiv.slideClassOut{
  transition: 1.0s;
  opacity: 0;
 }

 #fadeDiv {
  opacity: 0;
  transition: 1.0s;
  overflow: hidden;
 }

 #fadeDiv.fadeClassIn{
  margin-left: 0px;
  transition: 1.0s;
  opacity: 1;
 }

 #fadeDiv.fadeClassOut{
  margin-left: 0px;
  transition: 1.0s;
  opacity: 0;
 }

 .news {
  margin-bottom: 25px;
 }

.stickyParent {
  position:absolute;
  height:1000px;
  z-index:10;
  margin-top: -150px;
}

.stickyMenu{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 150px;
  
  border: 0px;
  border-radius: 8px;
  box-shadow:0px 0px 8px #575757;
  background-color: #fff;
}

div.sticky.colorKey {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 150px;
}

#collapseDiv {
  opacity: 1;
  overflow: hidden;
}
 
#collapseDiv.collapseDivClose{
  transition: 0.3s;
  opacity: 0;
  overflow: hidden;
}
  
#collapseDiv.collapseDivOpen{
  transition: 0.3s;
  opacity: 1;
  overflow: hidden;
}

.toolCard {
  margin: 10px;
  padding: 5px;
  border: 0px;
  border-radius: 8px;
  box-shadow:0px 0px 8px #575757;
  background-color: #fff;
}

hr.vertical {
  height:100%; /* you might need some positioning for this to work, see other questions about 100% height */
  width:0;
  border:1px solid black;
}

.talks-btn {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  background: #ffcf00;
  box-shadow: 2px 2px 5px #b7b99d;
  padding: 5px;
  border-radius: 10px;
}

.talks-btn .centered {
  text-align: center;
  font-size: 24px;
  padding: 0em 10px 0em 10px;
  color: #272727;
  left:25%;
  transform: translate(-15%, -50%);
}

.sidebar {
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #ffffff;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow:0px 0px 8px #575757;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  margin-left: -15px;
  margin-top: -10px;
}

.openbtn {
  font-size: 24pt;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 10px 15px;
  border: none;
  float:right;
}

.openbtn:hover {
  background-color: #444;
}

.tool-step-img {
  display: grid;
  grid-template-columns:repeat(3, 25%);
}

.tool-step-text {
  display: grid;
}

.popup {
  position: absolute;
  height: auto;
  display: none;
  z-index: 1;
  max-width: 80%;
  min-width: 50%;
  word-wrap: break-word;
}

.answers {
  font-size: 15px;
  margin: 0px;
  padding-top:5px;
}

.subtitle {
  margin-top:0px;
  text-align:left;
  white-space:pre-wrap;
  max-width:91%;
}

.about_faq_header {
  margin-top:0px;
  text-align:left;
  white-space:pre-wrap;
  max-width:90%;
}


.faq_title {
  margin-top:30px;
  text-align:left;
  white-space:pre-wrap;
  max-width:90%;
}

.faqQuestion {
  margin-bottom:30px;
}

.home-card {
  margin: 20px auto;
  border: 0px;
  border-radius: 15px;
  box-shadow:0px 0px 8px #9f9f9f;
}

.home-card p {
  font-size: 10pt;
}



.toolCheckList {
  padding-left: 0px;
}

.toolCheckList li {
  padding:2px 0px;
}

.toolCheckItem {
  user-select: none;
  font-size: 10pt;
  font-weight: bold;
}

.toolCheckItem input[type=radio]{
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.verticleLine {
  border-left: 2px solid #cecfd0;
  height: 100%;
  height: auto;
}

.carousel-inner {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow:0px 0px 8px #9f9f9f;
}

.carousel-dark {
  background-color: #fff1cf;
}

.toolpage tr:first-child {
  border-top: none;
}

.toolpage tr:first-child td {
  padding-top: 0px;
}

.toolpage tr {
  border-top: 1px solid #bbb6b6;
}

.toolpage tr td {
  padding-top: 23px;
  padding-bottom: 23px;
}

@media only screen
  and (min-width: 771px) {
    .grid-2 {
      grid-template-columns: repeat(2, 48%);
    }
    
    .grid-2 .line-single {
      display: none;
    }

    .App-header {
      height: 45px;
    }
}

