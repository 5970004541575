body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, -apple-system,
    BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-weight: 300;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0px;
  font-style: normal;
  line-height: 1.2em;
  font-size: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.04em;
}

h3 {
  text-transform: uppercase;
  text-decoration: none;
}

h4 {
  text-transform: uppercase;
  text-decoration: none;
}

strong {
  font-weight: 800;
}

.logo {
  height: 78px;
  display: inline-block;
}

.logo img {
  max-height: 100%;
  max-width: 40%;
  display: block;
}

.header-area {
  height: 100px;
}

.navbar-area {
  transform: translateZ(500px);
}

a {
  text-decoration-line: underline;
  text-decoration-color: rgba(0, 0, 0, 0.2);
  color: #000;
}

th {
  padding-left: 3px;
  background: #ccc;
}

td {
  padding-left: 3px;
}

.even {
  background: #eee;
}

.odd {
  background: #fff;
}

.navbar {
  margin-top: 50px;
}

.store-link {
  max-width: 300px;
}
