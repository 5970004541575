
.selected-item {
    text-align: center;
  }
  
  .controls-container {
    --highlight-width: auto;
    --highlight-x-pos: 0;
  
    display: flex;
    margin: 70px 0 25px;
  }

  .controls-container-sm {
    --highlight-width: auto;
    --highlight-x-pos: 0;
  
    display: flex;
    margin: 0;
  }

  .controls-container-lg {
    --highlight-width: auto;
    --highlight-x-pos: 0;
  
    display: flex;
    margin: 70px 0 25px;
  }

  .controls-containerPDF {
    --highlight-width: auto;
    --highlight-x-pos: 0;
  
    display: flex;
    margin-top: 10px;
    padding-bottom: 4px;
  }
  
  .controls {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px;
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 85px;
  }
  
  .controls-sm {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 45px;
  }

  .controls-lg {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px;
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 105px;
  }

  .controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .controls-sm input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .controls-lg input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .controlsPDF {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px;
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 65px;
  }
  
  .controlsPDF input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .segment {
    /* width: 100%; uncomment for each segment to have matching width */
    min-width: 100px;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  .segment-sm {
    /* width: 100%; uncomment for each segment to have matching width */
    min-width: 80px;
    position: relative;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    display: block;
    font-weight: 700;
    padding: 10px;
  }
  
  .segment label {
    cursor: pointer;
    font-weight: 700;
    padding: 10px;
    transition: color 0.5s ease;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .segment.active label {
    color: #fff;
  }
  
  .segment-lg {
    /* width: 100%; uncomment for each segment to have matching width */
    min-width: 250px;
    position: relative;
    text-align: center;
    z-index: 1;
  }
  
  .segment-lg label {
    cursor: pointer;
    display: block;
    font-weight: 700;
    font-size: 14pt;
    padding: 10px 10px 0px 10px;
    margin-bottom: 5px;
    transition: color 0.5s ease;
  }
  
  .segment-lg.active label {
    color: #fff;
  }
  .segment-lg.active img {
    -webkit-filter: invert(1);
    filter: invert(1);
  }
  .segment-img  {
    width: 30px;
    height: 30px;
 }

  .controls::before {
    content: "";
    background: #facc37;
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 0;
  }

  .controls-sm::before {
    content: "";
    background: #facc37;
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 0;
  }
  
  /* Only allow transitions once component is ready */
  .controls-sm.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  
  .controls-lg::before {
    content: "";
    background: #facc37;
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 0;
  }
  
  /* Only allow transitions once component is ready */
  .controls-lg.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  /* Only allow transitions once component is ready */
  .controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  .controlsPDF::before {
    content: "";
    background: #facc37;
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 0;
  }
  
  /* Only allow transitions once component is ready */
  .controlsPDF.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }